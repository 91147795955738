import GATSBY_COMPILED_MDX from "/Users/tanelsepp/Documents/PROJEKTID/wwwroot/give.ee/give3/content/blog/digi-vs-printreklaam-vaikeettevotjale/index.mdx";
import React, {useRef, useEffect} from 'react';
import {graphql} from 'gatsby';
import {MDXProvider} from '@mdx-js/react';
import Layout from '../components/Layout';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import Videoplayer from '../components/Videoplayer';
import OpenProjectTestimonial from '../components/OpenProjectTestimonial';
import SEO from '../components/seo';
import blogTagsHelper from '../Helpers/BlogTagsHelper';
import Img, {EmbeddedImagesProvider} from '../components/Img';
import '../styles/layout/blog.scss';
import {t} from 'i18next';
gsap.registerPlugin(ScrollTrigger);
const IMAGE_KEY = 'image';
const components = {
  Img: props => {
    return React.createElement(Img, Object.assign({}, props, {
      embeddedImages: embeddedImagesByKey
    }));
  },
  Videoplayer,
  OpenProjectTestimonial
};
function SingleProject({data, children}) {
  const {frontmatter: {title, titleForView, category, metaDescription, description, descriptionForView, author, authortitle, dateTimePublished, dateTimeUpdated, authorphoto, coverimage, coverimagealt, imageVariant16x9, imageVariant4x3, imageVariant1x1, embeddedImages, tags, slug}} = data.project;
  let useTitleForView = titleForView || title;
  const finalDescription = metaDescription || description;
  const useDescriptionForView = descriptionForView || description || metaDescription;
  const embeddedImagesByKey = embeddedImages && embeddedImages.reduce((images, image, index) => {
    images[`image${index + 1}`] = image;
    return images;
  }, {});
  let openProjectContent = useRef(null);
  useEffect(() => {
    if (typeof window === 'undefined') return;
    ScrollTrigger.matchMedia({
      '(min-width: 980px)': function () {
        gsap.utils.toArray(openProjectContent.children).forEach(el => {
          const targets = el;
          const targetsinner = el.getElementsByTagName('picture');
          const tl = gsap.timeline({
            scrollTrigger: {
              trigger: el,
              scrub: false,
              markers: false,
              start: 'top 100%',
              end: 'bottom top',
              toggleActions: 'play complete complete reverse'
            }
          });
          tl.from(targets, {
            opacity: 0,
            transformOrigin: '50% 50%',
            ease: 'ease-in',
            duration: 2
          }).from(targetsinner, {
            scale: 1.2,
            transformOrigin: '50% 50%',
            ease: 'ease-in',
            duration: 3
          }, '-=2');
        });
      },
      '(max-width: 799px)': function () {},
      all() {}
    });
  }, []);
  let imageSources = [];
  let openGraphOne = null;
  if (imageVariant16x9) {
    imageSources.push("https://give.ee" + imageVariant16x9.childImageSharp.gatsbyImageData.images.fallback.src);
    openGraphOne = {
      url: "https://give.ee" + imageVariant16x9.childImageSharp.gatsbyImageData.images.fallback.src
    };
  }
  if (imageVariant1x1) {
    imageSources.push("https://give.ee" + imageVariant1x1.childImageSharp.gatsbyImageData.images.fallback.src);
  }
  if (imageVariant4x3) {
    imageSources.push("https://give.ee" + imageVariant4x3.childImageSharp.gatsbyImageData.images.fallback.src);
  }
  if (imageSources.length < 1 && coverimage) {
    imageSources.push("https://give.ee" + coverimage.childImageSharp.gatsbyImageData.images.fallback.src);
    openGraphOne = {
      url: "https://give.ee" + coverimage.childImageSharp.gatsbyImageData.images.fallback.src
    };
  }
  let dateTime = dateTimePublished ? dateTimePublished.substr(0, 10) : "";
  let authorStructuredData = {
    "@type": "Person",
    name: author
  };
  if (author === "Tanel Kaarel Sepp") {
    authorStructuredData.sameAs = ["https://weekendvisuals.com", "https://x.com/weekendvisuals", "https://www.linkedin.com/in/tanel-kaarel-sepp-product-designer/"];
  }
  const getReadingTimeInMinutes = text => {
    const wordsPerMinute = 200;
    const numberOfWords = text.trim().split(/\s+/).length;
    const readingTime = Math.max(1, Math.ceil(numberOfWords / wordsPerMinute));
    return {
      readingTime,
      wordCount: numberOfWords
    };
  };
  const {readingTime, wordCount} = getReadingTimeInMinutes(data.project.body);
  let structuredData = {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://give.ee/artiklid/" + slug + "/"
    },
    headline: title,
    description: description || metaDescription || "Loe Give digiagentuuri artikleid",
    image: imageSources,
    author: authorStructuredData,
    publisher: {
      "@type": "Organization",
      name: "Digiagentuur Give",
      logo: {
        "@type": "ImageObject",
        url: "https://give.ee/give-digital-agency-logo-white-bg.png"
      }
    },
    datePublished: dateTime,
    dateModified: dateTimeUpdated,
    wordCount: wordCount,
    timeRequired: `PT${readingTime}M`,
    articleSection: category || "Artiklid",
    keywords: tags || []
  };
  if (authortitle?.toLowerCase() === "disainer") {
    structuredData.author.jobTitle = "Designer";
  }
  let openGraphImages = [];
  if (openGraphOne) {
    openGraphImages.push(openGraphOne);
  }
  return React.createElement(Layout, null, React.createElement("article", {
    className: "open-blog-post inner-content",
    ref: el => openProjectContent = el
  }, React.createElement(SEO, {
    title: title,
    description: finalDescription,
    article: true,
    structuredData: structuredData,
    openGraphImages: openGraphImages
  }), React.createElement("section", {
    className: "title"
  }, React.createElement("div", {
    className: "tags"
  }, tags.map((singletag, index) => {
    const tagTitle = blogTagsHelper.getTitleFromSlug(singletag);
    return React.createElement("span", {
      className: "tag",
      key: index
    }, tagTitle);
  })), React.createElement("h1", {
    dangerouslySetInnerHTML: {
      __html: useTitleForView
    }
  }), React.createElement("div", {
    className: "post-info"
  }, React.createElement("div", {
    className: "author"
  }, React.createElement(GatsbyImage, {
    image: authorphoto.childImageSharp.gatsbyImageData,
    alt: title,
    className: "author-thumbnail"
  }), React.createElement("div", {
    className: "author-meta"
  }, React.createElement("span", {
    className: "author-name"
  }, author))), React.createElement("div", {
    className: "reading-time"
  }, "Lugemisaeg ", readingTime, " minutit")), React.createElement("div", {
    className: "post-intro",
    dangerouslySetInnerHTML: {
      __html: useDescriptionForView
    }
  })), coverimage && React.createElement(GatsbyImage, {
    image: coverimage.childImageSharp.gatsbyImageData,
    alt: coverimagealt,
    className: "post-cover"
  }), React.createElement(MDXProvider, {
    components: {
      Img: props => React.createElement(Img, Object.assign({}, props, {
        embeddedImages: embeddedImagesByKey
      })),
      Videoplayer,
      OpenProjectTestimonial
    }
  }, children)));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(SingleProject, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const query = graphql`
  query ($slug: String!) {
    project: mdx(frontmatter: { slug: { eq: $slug } }) {
      id
      body
      frontmatter {
        title
        titleForView
        category
        metaDescription
        description
        descriptionForView
        author
        slug
        dateTimePublished
        dateTimeUpdated
        authortitle
        authorphoto {
          childImageSharp {
            gatsbyImageData(width: 48, height: 48, layout: FIXED, placeholder: BLURRED)
          }
        }
        tags
        coverimage {
          childImageSharp {
            gatsbyImageData(width: 1200, height: 630, layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        coverimagealt
        imageVariant1x1 {
          childImageSharp {
            gatsbyImageData(width: 1200, height: 1200, layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        imageVariant4x3 {
          childImageSharp {
            gatsbyImageData(width: 1200, height: 900, layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        imageVariant16x9 {
          childImageSharp {
            gatsbyImageData(width: 1200, height: 675, layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        embeddedImages {
          childImageSharp {
            gatsbyImageData(width: 1200, height: 675, layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;
